import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Keep application related configs in this file

// all the page urls of the website
export const PAGE_URL = {
  HOMEPAGE: '/',
  SERVICES_B2C: '/Services/B2C-Xpress',
  SERVICES_B2B: '/Services/B2B-Xpress',
  SERVICES_CBL: '/Services/CrossBorderLogistics',
  SERVICES_TPL: '/Services/ThirdPartyLogistics',
  SERVICES_PAGE: '/Services/Service',
  CAREER_PAGE: '/Career',
  ABOUT_US: '/AboutUs',
  LIFE_AT_XB: '/AboutUs#life',
  NEWSPAGE: '/News',
  NEWSPAGE_ALL: '/News/All',
  NEWSPAGE_SINGLE: '/News/:newsId/:newsTitle',
  CONTACT_US: '/ContactUs',
  MEDIA_BLOGS: '/Blogs',
  MEDIA_BLOGS_ALL: '/Blogs/:blogsName',
  MEDIA_BLOGS_SINGLE: '/Blogs/:blogId/:blogTitle',
  PRIVACY_POLICY: '/policies',
  VENDOR_POLICY: '/policies/vendor-policies',
  CODE_OF_CONDUCT: '/policies/code-of-conduct',
  ANTI_CORRUPTION: '/policies/anti-corruption-policy',
  SHIPMENT_TRACKING: '/shipment/tracking',
  TERMS_OF_USE: '/policies/terms-of-use',
  TEST: '/test',
  OLD_WEBSITE_TRACKING_PAGE: '/track',
  PARTNER: '/partner',
};

export const PAGE_URL_REGEX = {
  SHIPMENT_TRACKING: /^\/shipment\/tracking\/[0-9]+$/i,
};

export const LANGUAGES = {
  en: 'English',
};

export const DEVICE_SIZES = {
  tablet: 768,
  laptop: 992,
  desktop: 1200,
};

export const DEVICE_TYPE = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  LAPTOP: 'LAPTOP',
  DESKTOP: 'DESKTOP',
};

export const DEVICES = {
  sMobile: `(max-width: 480px)`,
  mobile: `(max-width: ${DEVICE_SIZES.tablet}px)`,
  tablet: `(min-width: ${DEVICE_SIZES.tablet}px) and (max-width:${DEVICE_SIZES.laptop}px)`,
  laptop: `(min-width: ${DEVICE_SIZES.laptop}px) and (max-width:${DEVICE_SIZES.desktop}px)`,
  desktop: `(min-width: ${DEVICE_SIZES.desktop}px)`,
};

export const EventBus = {
  UPDATE_META_INFORMATION: 'UPDATE_META_INFORMATION',
};
export const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default ScrollToTop;
